body, html {
    margin: 0;
    padding: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: #333;
    font-weight: 400;
    //background: #f3f3f6;
    font-size: 14px;
    overflow: hidden;
}
.tox-statusbar__branding {
    display: none;
}
a {
    color: #333;
    text-decoration: none;
    transition: all .3s;
}
a:focus, a:hover {
    color: #2DC079;
}
.clearFix {
    zoom: 1;
}
.clearFix:after {
    content: '';
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}
.text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.text-overflow-2 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.flex {
    display: flex;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
//绿色头部对话框
.green-dialog{
    .el-dialog__header{
        padding: 16px 24px;
        background: #2DC079;
        span,i{
            font-size: 16px;
            line-height: 1;
            color: #fff;
        }
    }
    .el-dialog__body{
        padding:22px 24px;
        // 带表格的
        .el-form-item{
            margin-bottom: 26px;
        }
        .el-form-item__label{
            /*padding: 0;
            line-height: 1;*/
            font-size: 16px;
            color: #333333;
            //margin-bottom: 10px;
        }
        .dialog-footer{
            text-align: center;
        }
    }
}
.green-dialog02{
    .el-dialog__header{
        padding: 16px 24px;
        background: #2DC079;
        span,i{
            font-size: 16px;
            color: #fff;
        }
    }
    .el-dialog__body{
        //padding:22px 24px;
        // 带表格的
        .el-form-item{
            margin-bottom: 20px;
        }
        .el-form-item__label{
            //padding: 0;
            //line-height: 1;
            font-size: 20px;
            color: #333333;
            //margin-bottom: 10px;
        }
    }
}
// message-box绿色头部
.green-message-box{
    width: 500px;
    border: none;
    padding-bottom: 0;
    .el-message-box__header{
        background: #2DC079;
        padding: 17px 24px 15px;
        span,i{
            color: #fff;
            font-size: 16px;
        }
    }
    .el-message-box__content{
        height: 160px;
        box-sizing: border-box;
        //text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-message-box__container{
            display: flex;
            align-items: center;
            .el-message-box__status{
                position: relative;
                transform:none;
                top: 0;
                font-size: 16px!important;
                color: #EB3800;
            }
            .el-message-box__message{
                padding: 0;
                margin-left: 10px;
                font-size: 16px;
                color: #333;
            }
        }
    }
    .el-message-box__btns{
        text-align: center;
        padding: 0 0 30px;
        .el-button{
            padding: 11px 23px;
            font-size: 16px;
            &:last-child{
                margin-left: 30px;
            }
        }
    }
}
.el-link + .el-link {
    margin-left: 22px;
    &:before {
        content: '';
        position: absolute;
        left: -12px;
        width: 2px;
        height: 16px;
        background: #DFE9FF;
    }
}

//S table自定义表格
.customTable {
    color: #333;
    font-size: 16px;
    th {
        height: 60px;
        color: #666;
        font-weight: normal;
        background: #F6F6F6;
        border-top: 1px solid #eee;
        &:first-child {
            border-left: 1px solid #eee;
            & > .cell {
                //padding-left: 40px;
            }
        }
        &:nth-last-child(2) {
            border-right: 1px solid #eee;
        }
        &.gutter {
            border-right: 1px solid #eee;
        }
    }
    td {
        &:first-child {
            border-left: 1px solid #eee;
            & > .cell {
                //padding-left: 40px;
            }
        }
        &:last-child {
            border-right: 1px solid #eee;
        }
    }
    td, th.is-leaf {
        border-color: #eee;
    }
    &:before {
        content: none;
    }
    .el-table__empty-block {
        border-top: none;
        box-sizing: border-box;
    }
    &.tableSmall {
        font-size: 14px;
        th {
            color: #333;
            height: 48px;
            &:first-child {
                & > .cell {
                    padding-left: 10px;
                }
            }
        }
        td {
            border-right: 1px solid #eee;
            &:first-child {
                & > .cell {
                    padding-left: 10px;
                }
            }
        }
    }
}
//E table自定义表格

//分页样式
.pages-center {
    margin: 20px 0;
    text-align: center;
}
//搜索框样式
.w300-input {
    width: 300px;
    .el-input-group__append {
        background-color: #2DC079;
        color: #fff;
        padding: 0 8px;
    }
}
//下拉选择框样式
.w350-cascader {
    width: 350px;
    .el-input__inner {
        height: 32px;
        border: 1px solid #DCDCDC;
        border-radius: 0;
    }
}
.w200-select {
    width: 200px;
    .el-input__inner {
        height: 32px;
        border: 1px solid #DCDCDC;
        border-radius: 0;
    }
    .el-input__icon {
        line-height: 32px;
    }
}

//面包屑
.genera-breadcrumb {
    position: relative;
    height: 42px;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        background: #F1F5FF;
    }

    .el-breadcrumb__item {

        &:last-child .el-breadcrumb__inner {
            color: #333333;
        }

        &:nth-last-child(2) i {
            color: #333333;
        }

        .el-breadcrumb__inner {
            color: #999999;
        }
    }
}
//步骤

.steps-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    .step-item {
        width: 329px;
        margin-right: 12px;
        position: relative;
        .step-line {
            width: 271px;
            height: 14px;
            position: absolute;
            //right: 58px;
            left: -281px;
            top: 17px;
            background: url("../../assets/images/admin/step-gray.png") no-repeat;
            background-size: cover;
        }
        .step-text {
            width: 48px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background: #DCDCDC;
            border-radius: 50%;
            font-size: 24px;
            color: #fff;
        }
        .step-title {
            margin-left: calc(-50% + 48px);
            margin-right: 50%;
            text-align: center;
            margin-top: 20px;
            font-size: 16px;
        }
        &:first-child {
            .step-line {
                display: none;
            }
        }
        &:last-child {
            width: auto!important;
            margin-right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .step-title {
                white-space: nowrap;
                margin-left: 0;
                margin-right: 0;
            }
        }
        &.current {
            .step-text {
                background: #2DC079;
            }
            .step-line {
                background-image: url("../../assets/images/admin/step-active.png");
            }
        }
    }
    &.steps-mini {
        .step-item {
            width: 247px;
            .step-line {
                width: 189px;
                left: -199px;
                background: url("../../assets/images/admin/step-gray-mini.png") no-repeat;
            }
            &.current {
                .step-line {
                    background-image: url("../../assets/images/admin/step-active-mini.png");
                }
            }
        }
    }
}
// 验证输入框变红
.isError{
    .el-input__inner{
        border-color: #FF0000!important;
    }
    .el-textarea__inner{
        border-color: #FF0000!important;
    }
}

//S 自定义dialog
.custom-dialog {
    .el-dialog__header {
        padding: 16px 24px;
        background: #2DC079;
        span,i {
            font-size: 16px;
            line-height: 1;
            color: #fff;
        }
    }
    .el-dialog__body {
        padding: 22px 24px;
        .dialog-footer {
            text-align: center;
        }
    }
}
//E 自定义dialog

//S 自定义breadcrumb
.customBreadcrumb {
    .el-breadcrumb__inner {
        color: #666;
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
        color: #333;
    }
}
//E 自定义breadcrumb


//S 学生端 chat
//S dialog 样式覆盖
.dialog-student {
    .el-dialog__header {
        padding: 10px 30px;
        position: relative;
        background: linear-gradient(91deg, #0083FF 0%, #00B8FF 100%);
        .el-dialog__title {
            font-size: 14px;
            color: #fff;
            line-height: 1;
        }
        .el-dialog__headerbtn {
            top: 11px;
            .el-dialog__close {
                color: #fff;
            }
        }
    }
    .el-dialog__body {
        padding: 20px;
    }
    .el-dialog__footer {
        padding: 0 20px 20px;
    }
}
//E dialog 样式覆盖
.link-blue-shadow {
    &.el-link--default {
        color: #0C6AFF;
        &:hover {
            color: #3795ff;
        }
    }
}
.btn-yellow {
    color: #fff;
    background: #FFB000;
    border-color: #FFB000;
    &:focus,
    &:hover {
        color: #fff;
        border-color: #FFBF2F;
        background-color: #FFBF2F;
    }
}
.btn-blue-shadow {
    color: #0C6AFF;
    background: #DEEBFF;
    border-color: #DEEBFF;
    &:focus, &:hover, &.current {
        color: #fff;
        border-color: #0C6AFF;
        background-color: #0C6AFF;
    }
    &.is-disabled, &.is-disabled:focus, &.is-disabled:hover {
        color: #86b5ff;
        background-color: #f3f8ff;
        border-color: #f3f8ff;
    }
}
.btn-blue-stu {
    color: #fff;
    background: #0C6AFF;
    border-color: #5394ff;
    &:focus,
    &:hover {
        color: #fff;
        border-color: #64a0ff;
        background-color: #64a0ff;
    }
}
//S popover样式覆盖
.dialog-gradient {
    position: fixed;
    right: 647px;
    margin: 0!important;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    &:before {
        content: '';
        position: absolute;
        right: -5px;
        top: 50%;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 5px solid #fff;
        border-bottom: 5px solid transparent;
        transform: translateY(-50%);
        z-index: 1;
    }
    &:after {
        content: '';
        position: absolute;
        right: -6px;
        top: 50%;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 5px solid #EBEEF5;
        border-bottom: 5px solid transparent;
        transform: translateY(-50%);
    }
    .el-dialog__header {
        padding: 0 20px;
        line-height: 40px;
        background: linear-gradient(91deg, #0083FF 0%, #00B8FF 100%);
    }
    .el-dialog__title {
        color: #fff;
        font-size: 14px;
    }
    .el-dialog__headerbtn {
        top: 11px;
        .el-dialog__close {
            color: #fff;
        }
    }
    .el-dialog__body {
        padding: 0;
    }
    .edit-levelOne {
        border-radius: 4px;
        background: #fff;
        position: relative;
        .edit-l-content {
            padding: 16px 20px 0;
        }
        .edit-l-bottom {
            text-align: right;
            padding: 16px 20px;
        }
    }
}
//E popover样式覆盖
//E 学生端 chat
.customPopover {
    min-width: auto;
    font-size: 12px;
    color: #666;
    &.el-popover--plain {
        padding: 8px 10px;
    }
}
.number-input {
    .el-input__inner {
        text-align: left;
    }
}
.prompt-text {
    font-size: 16px;
    margin-left: 10px;
}

.btn-red {
    color: #fff;
    background: #FD4446;
    border-color: #FD4446;
    &:focus,
    &:hover {
        color: #fff;
        border-color: #fd5658;
        background-color: #fd5658;
    }
}

.btn-red-line {
    color: #666;
    border-color: #DCDCDC;
    &:focus,
    &:hover {
        color: #FD4446;
        border-color: #FD4446;
        background-color: transparent;
    }
}

.btn-red-line-all {
    color: #FD4446;
    border-color: #FD4446;
    &:focus,
    &:hover {
        color: #EB6547;
        border-color: #EB6547;
        background-color: transparent;
    }
}

.btn-w-140 {
    width: 140px;
    font-size: 20px;
}

/*单选框组 样式覆盖*/
.radioGroup-red {
    .el-radio {
        color: #333;
        font-weight: normal;
        .el-radio__label {
            font-size: 18px;
        }
        .el-radio__inner {
            width: 20px;
            height: 20px;
        }
        .el-radio__inner:hover {
            border-color: #FD4446;
        }
        .el-radio__input.is-checked .el-radio__inner {
            border-color: #FD4446;
            background: #FD4446;
        }
        .el-radio__input.is-checked + .el-radio__label {
            color: #FD4446;
        }
    }
}
.custom-drawer {
    .btn-close {
        position: absolute;
        right: 30px;
        top: 30px;
        font-size: 30px;
        cursor: pointer;
    }
}
.zIndex9999{
    z-index: 9999!important;
}
.assign-goods-dialog {
    .assign-search {
        margin-bottom: 15px;
        .practice-cascader {
            width: 273px;
            margin-left: 10px;
            ::v-deep .el-input__inner {
                border-radius: 2px;
            }
        }
    }
    .upload-file-content {
        height: 287px;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                height: 100%;
            }
        }
        .goods-checkbox-group {
            position: relative;
            height: 100%;
            padding: 12px;
            box-sizing: border-box;
            .goods-checkbox {
                width: 172px;
                padding-bottom: 10px;
                &:nth-child(3n) {
                    margin-right: 0;
                }
                ::v-deep .el-checkbox__label {
                    position: absolute;
                    width: 158px;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
            }

        }
    }
    .class-content {
        height: 287px;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        .class-cascader {
            height: 100%;
            border: none;
        }
    }
    ::v-deep .el-dialog__footer {
        text-align: center;
    }
}