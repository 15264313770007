
.view-container {
    width: 100vw;
    height: 100vh;
    min-width: 1200px;
    background: #F7F8FC;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .header {
        width: 100%;
        height: 60px;
        background: #fff;
        box-shadow: 0 2px 5px 0 rgba(15, 26, 54, 0.05);
        display: flex;
        z-index: 9;

        .login-box {
            width: 100%;
            font-size: 16px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            .title {
                font-size: 18px;
                font-weight: 400;
                color: #333;
            }
            .user-info {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .user-avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 50%;
                    }
                }

                .user-name {
                    width: 100px;
                    max-height: 50px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #333;
                }

                .logout-btn {
                    cursor: pointer;
                    margin-left: 20px;
                    color: #2DC079;
                }
            }
            .router {
                position: relative;
                height: 60px;
                flex: 1;
                width: 1%;
                margin: 0 20px;
                .one-text {
                    display: inline-block;
                    text-align: center;
                    width: 104px;
                }
                .levelOne {
                    position: absolute;
                    display: inline-block;
                    width: 104px;
                    height: 60px;
                    line-height: 60px;
                    color: #333;
                    padding: 0 20px;
                    transition: all .3s;
                    .one-icon {
                        width: 104px;
                        height: 4px;
                        background-color: transparent;
                        top: 54px;
                    }
                    &:nth-child(2) {
                        left: 144px;
                    }
                    &:nth-child(3) {
                        left: 288px;
                    }
                    &:nth-child(4) {
                        left: 432px;
                    }
                    &:nth-child(5) {
                        left: 576px;
                    }
                    &:nth-child(6) {
                        left: 720px;
                    }
                    &.current {
                        height: 60px;
                        color: #2DC079;
                        border-radius: 4px 4px 0 0;
                        span {
                            position: absolute;
                        }
                        .one-icon {
                            width: 104px;
                            height: 4px;
                            background-color: #2DC079;
                            top: 57px;
                        }
                    }
                }
            }
        }
    }

    .main {
        width: 100%;
        height: 1%;
        flex: 1;
        display: flex;
        .menu-box {
            width: 210px;
            height: 100%;
            margin-right: 20px;
            background-color: #fff;
        }
        .levelOne-box {
            width: 100%;
            height: 100%;
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .levelTwo {
                position: relative;
                height: 60px;
                padding: 0 10px 0 30px;
                display: flex;
                align-items: center;
                color: #666;
                line-height: 1;
                transition: all .3s;
                .two-text {
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 10px;
                }
                .icon {
                    display: inline-block;
                    width: 5px;
                    height: 60px;
                    background: transparent;
                    position: relative;
                    z-index: 999;
                    left: -30px;
                }
                &:hover {
                    color: #1AB163;
                }
                &.current {
                    color: #1AB163;
                    background: #E7F6EF;
                    .icon {
                        background: #1AB163;
                    }
                }
            }
        }
        .main-container {
            display: flex;
            flex: 1;
            flex-direction: column;
            width: 1%;
            margin: 20px;
            .content {
                flex: 1;
                height: 1%;
                position: relative;
            }
        }
    }
}
