
::v-deep .el-dialog {
    width: calc(100% - 120px);
    min-width: 1000px;
}

::v-deep .el-dialog__header::after {
    content: '';
    width: 100%;
    height: 10px;
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid #EEEEEE;
}

::v-deep .el-dialog__footer {
    text-align: center;
}

::v-deep .el-dialog__body {
    position: relative;
}

.featured-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    ::v-deep .el-dialog {
        margin-top: 10vh !important;
    }

    .course-index {
        width: 100%;
        height: 100%;
        /*min-width: 1460px;*/
        background-size: 100% 100%;
        background: #ffffff;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                height: 100%;
            }
        }

        .course-content {
            height: 100%;
        }

        .top-box {
            width: 100%;
            background: #ffffff;
            display: flex;
            /*flex: 1;*/

            .top-leftBox {
                width: 586px;
                height: 330px;
                padding: 60px 60px 32px 60px;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            .top-rightBox {
                height: 330px;
                max-height: 330px;
                padding: 60px 60px 32px 0;

                .rightBox-course {
                    display: flex;
                    margin-top: 20px;

                    .course-title-box1 {
                        font-size: 24px;
                        font-weight: 400;
                        color: #313F54;
                    }

                    .course-title-box2 {
                        width: 110px;
                        background-image: url("../../../assets/images/teacher/course/courseIndex.png");
                        margin-left: 30px;
                        text-align: center;
                        color: #FFFFFF;
                        height: 30px;

                        i {
                            margin-right: 8px;
                            font-size: 14px;
                        }
                    }

                    .course-title-midBox {
                        font-size: 16px;
                        color: #313F54;
                        max-width: 700px;
                        max-height: 150px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;

                        span {
                            font-size: 14px;
                            word-break: break-all;
                            line-height: 1.5;
                            max-height: 120px;
                        }
                    }
                }
            }
        }

        .bottom-box {
            width: 100%;
            flex: 1;
            background: #ffffff;
            margin-top: 30px;

            .item-content {
                &:last-child {
                    padding: 100px 0;

                }

                .item-head {
                    text-align: center;
                }

                .item-title {
                    font-size: 20px;
                    color: #333333;
                    position: relative;

                    &:before {
                        position: absolute;
                        width: 34px;
                        height: 2px;
                        content: '';
                        background: #2DC079;
                        top: 14px;
                        left: -64px;
                    }

                    &:after {
                        position: absolute;
                        width: 34px;
                        height: 2px;
                        content: '';
                        background: #2DC079;
                        top: 14px;
                        right: -64px;
                    }
                }

                .item-main {
                    padding: 80px 60px 0;
                }

                .item-tree-content {
                    margin-top: 23px;

                    .item-tree {
                        font-size: 20px;
                        color: #333333;

                        &.one-title {
                            .title-index {
                                font-weight: bold;
                            }

                            .title-text {
                                margin-left: 5px;
                            }
                        }

                        &.two-title {
                            display: flex;
                            align-items: center;
                            height: 48px;
                            background: #EEFFFC;
                            margin-top: 10px;

                            span {
                                padding-left: 60px;
                            }
                        }

                        &.three-title {
                            display: flex;
                            align-items: center;
                            height: 48px;
                            background: #F6FDFC;
                            margin-top: 10px;

                            span {
                                padding-left: 90px;
                            }
                        }
                    }
                }

                .item-table {

                    &:last-child {
                        margin-top: 41px;
                    }

                    .table-title {
                        font-size: 18px;
                    }

                    .table-content {
                        margin-top: 22px;

                        ::v-deep.table-wrap {
                            table {
                                border-collapse: collapse !important;
                                width: 100% !important;
                                border-color: transparent;
                                border-bottom: none;
                                margin-top: -4px;

                                tr {
                                    height: auto !important;

                                    &:nth-child(even) {
                                        td {
                                            background: #E9F6EF;
                                            color: #333333;
                                        }
                                    }

                                    &:nth-child(odd) {
                                        td {
                                            background: #56CA95;
                                        }
                                    }

                                    th {
                                        background: #56CA95;
                                    }

                                    th, td {
                                        border-right: 4px solid #fff;
                                        border-top: 4px solid #fff;

                                        &:first-child {
                                            border-left: 0;
                                            /*text-align: center;*/
                                        }

                                        &:last-child {
                                            border-right: 0;
                                            /*text-align: center;*/
                                        }
                                    }

                                    th {
                                        text-align: center;
                                        padding: 20px;
                                        height: 20px !important;
                                        line-height: 20px;
                                        box-sizing: border-box;
                                        font-size: 18px;
                                        color: #fff;
                                        white-space: nowrap;
                                    }

                                    td {
                                        height: auto !important;
                                        text-align: center;
                                        padding: 14px;
                                        font-size: 18px;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
