
.examData-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .container {
        height: 1%;
        flex: 1;
        position: relative;
    }
}
.top-tab {
    margin: 10px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: #F1F5FF;
    }
    .right {
        width: 90px;
        text-align: right;
        margin-top: -5px;
    }
}
.tabs-full {
    width: 1%;
    flex: 1;
    ::v-deep {
        .el-tabs__nav-wrap::after {
            content: none;
        }
        .el-tabs__header {
            margin-bottom: 0;
        }
        .el-tabs__active-bar {
            display: none;
        }
        .el-tabs__item {
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: normal;
            border-radius: 4px 4px 0 0;
            &.is-active {
                background: #E7F6EF;
            }
        }
        .el-tabs__item.is-top:nth-child(2) {
            padding-left: 20px;
        }
        .el-tabs__item.is-top:last-child {
            padding-right: 20px;
        }
    }
}
