
.stepActive {
    background: #20D08C;
    border-color: #20D08C!important;
}
.xianActive {
    border-color: #20D08C!important;
}
.info-step {
    height: 100%;
    background: #F7F9FB;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .info-step-header {
        padding: 22px 0 20px 40px
    }
    .info-step-box {
        display: flex;
        .info-step-left {
            width: 190px;
            margin-left: 40px;
            .left-creation {
                display: flex;
                flex-direction: column;
                p:nth-child(1) {
                    margin: 0;
                    padding: 20px 0;
                    line-height:1;
                    /*color: #333333;*/
                    color: #959697;
                    font-size: 48px;
                    span {
                        font-size: 24px;
                    }
                    &.isActive {
                        color: #333;
                    }
                }
                .step-one {
                    .step-name {
                        display: flex;
                        flex-direction: column;
                        &.isActive {
                            .num {
                                background: #1FD08C;
                                color: #fff;
                            }
                            .name {
                                color: #333333;
                            }
                        }
                        .num {
                            width: 24px;
                            height: 24px;
                            background: #fff;
                            border-radius: 50%;
                            display: inline-block;
                            text-align: center;
                            color: #1FD08C;
                            line-height: 24px;
                            font-size: 16px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            border: 1px solid #1FD08C;
                        }
                        .name {
                            font-size: 18px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            color: #999;
                            margin-left: 15px;
                        }
                        .icon {
                            display: inline-block;
                            width: 1px;
                            height: 41px;
                            background: #20D08C;
                            margin: 10px;
                        }
                    }
                    .step-small-name {
                        display: flex;
                        flex-direction: column;
                        &.isActive {
                            .num {
                                background: #1FD08C;
                                color: #fff;
                            }
                            .name {
                                color: #333333;
                            }
                        }
                        .num {
                            width: 18px;
                            height: 18px;
                            background: #fff;
                            border-radius: 50%;
                            display: inline-block;
                            text-align: center;
                            color: #1FD08C;
                            line-height: 24px;
                            font-size: 16px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            border: 1px solid #1FD08C;
                        }
                        .name {
                            font-size: 16px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            color: #999;
                            margin-left: 15px;
                        }
                        .icon {
                            display: inline-block;
                            width: 1px;
                            height: 41px;
                            background: #20D08C;
                            margin: 10px;
                        }
                    }
                }
            }
        }
        .info-step-right{
            width: 1%;
            flex: 1;
            margin: 20px 30px 50px 0;
        }
    }
}
