/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
    font-family: 'iconfont';  /* Project id 2378340 */
    src: url('//at.alicdn.com/t/c/font_2378340_jf8v1e0p68s.eot?t=1710122434071'); /* IE9 */
    src: url('//at.alicdn.com/t/c/font_2378340_jf8v1e0p68s.eot?t=1710122434071#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('//at.alicdn.com/t/c/font_2378340_jf8v1e0p68s.woff2?t=1710122434071') format('woff2'),
         url('//at.alicdn.com/t/c/font_2378340_jf8v1e0p68s.woff?t=1710122434071') format('woff'),
         url('//at.alicdn.com/t/c/font_2378340_jf8v1e0p68s.ttf?t=1710122434071') format('truetype'),
         url('//at.alicdn.com/t/c/font_2378340_jf8v1e0p68s.svg?t=1710122434071#iconfont') format('svg');
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}