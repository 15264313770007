
.student-container {
    background: #F7F9FB;
    width: 100vw;
    height: 100vh;
    display: flex;

    .left-wrapper {
        width: 280px;
        background: #2C405F;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-weight: 500;

        .left-header {
            text-align: center;

            .logo {
                margin-top: 36px;
                display: inline-block;
                width: 60px;
                height: 60px;
                background: #FD4446;
                border-radius: 10px;
                text-align: center;
                line-height: 60px;

                .iconfont {
                    display: block;
                    font-size: 33px;
                    color: #fff;
                }
            }

            .theme {
                margin-top: 22px;
                font-size: 28px;
                color: #fff;
                line-height: 1;
            }
        }

        .left-menu-wrapper {
            margin-top: 50px;
            height: 1%;
            flex: 1;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .left-menu {
                height: 100%;
                background: transparent;
                border-right: none;
            }

            .el-menu-item {
                height: 60px;
                line-height: 60px;
                padding: 0 !important;

                a.nav-item {
                    height: 100%;
                    color: #fff;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    padding: 0 20px 0 40px;
                    position: relative;
                    transition: all .3s;

                    .iconfont {
                        color: #F7F9FB;
                        font-size: 20px;
                        margin-right: 20px;
                        transition: all .3s;
                    }
                }

                &:focus, &:hover, &.is-active {
                    background-color: #25354F;
                }

                /*&.is-active, &.is-opened {*/
                &.is-active {
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 2px;
                        height: 100%;
                        background: #FF4400;
                    }
                }
            }

            .el-submenu {
                height: auto;
                line-height: 60px;
                padding: 0 !important;

                ::v-deep .el-submenu__title {
                    height: 100%;
                    color: #F7F9FB;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    padding: 0 20px 0 40px !important;
                    position: relative;
                    transition: all .3s;

                    .iconfont {
                        color: #F7F9FB;
                        font-size: 20px;
                        margin-right: 20px;
                        transition: all .3s;
                    }

                    a {
                        color: #fff;
                    }

                    ::v-deep .el-submenu__icon-arrow {
                        color: #fff;
                        transform: rotateZ(270deg);
                    }

                    &:focus, &:hover, &.is-active {
                        background-color: #25354F;
                    }

                    &:focus {
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            width: 2px;
                            height: 100%;
                            background: #FF4400;
                        }
                    }
                }

                &:focus, &:hover, &.is-active {
                    background-color: #25354F;
                }

                /*&.is-active, &.is-opened {*/
                /*&:before {*/
                /*content: '';*/
                /*position: absolute;*/
                /*left: 0;*/
                /*width: 2px;*/
                /*height: 100%;*/
                /*background: #FF4400;*/
                /*}*/
                /*}*/
                ::v-deep .el-menu {
                    background: #2C405F;

                    .el-menu-item {
                        height: 60px;
                        line-height: 60px;
                        padding: 0 !important;

                        .nav-item {
                            height: 100%;
                            color: #F7F9FB;
                            font-size: 18px;
                            display: flex;
                            align-items: center;
                            padding: 0 20px 0 40px;
                            position: relative;
                            transition: all .3s;

                            a {
                                display: block;
                                width: 100%;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .right-wrapper {
        width: 1%;
        flex: 1;
        display: flex;
        flex-direction: column;

        .right-header {
            height: 80px;
            background: #fff;
            border-bottom: 1px solid #D1D2DB;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .header-tab {
                height: 100%;
                position: absolute;
                left: 30px;
                display: flex;
                align-items: center;
                font-weight: 500;
                color: #333333;

                .activeSelect {
                    a {
                        display: block;
                        height: 100%;
                        color: #FD4446 !important;
                        border-bottom: 2px solid #FD4446 !important;
                    }
                }

                .item-tab {
                    height: 100%;
                    margin-right: 25px;
                    cursor: pointer;
                    line-height: 81px;
                    border-bottom: 2px solid transparent;
                    font-size: 20px;

                    a {
                        color: #333333;
                        display: block;

                        &:hover {
                            color: #FD4446;
                        }
                    }
                }
            }

            .countdown {
                font-size: 18px;
                color: #FD4446;
                font-weight: 500;

                .time {
                    margin-left: 10px;
                }
            }

            .user-wrapper {
                position: absolute;
                right: 30px;
                display: flex;

                .account-balance {
                    display: flex;
                    align-items: center;
                    color: #FD4446;
                    font-weight: bold;
                    margin-right: 24px;
                }

                .user-info {
                    display: flex;
                    align-items: center;

                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .user-name {
                        margin-left: 6px;
                    }
                }

                .exit-btn {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;

                    .iconfont {
                        font-size: 20px;
                    }

                    span {
                        margin-left: 6px;
                    }

                    &:hover {
                        color: #1122D8;
                    }
                }
            }
        }

        ::v-deep .right-content {
            height: 1%;
            flex: 1;

            .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    min-width: 900px;
                    /*height: 100%;*/
                }

                .right-wrapper-content {
                    padding: 20px 30px 20px 40px;
                    background: #F7F9FB;
                    box-sizing: border-box;
                }
            }
        }
    }
}

::v-deep p {
    margin: 0;
}

.endPromotion {
    padding: 0 20px 0 42px;
    color: #ffffff;
    font-size: 17px;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
    transition: 0.5s;

    i {
        font-size: 18px;
    }

    span {
        margin-left: 20px;
    }
}

.endPromotion:hover {
    background: #25354F;
}

.train-dialog-content {
    display: flex;
    flex-direction: column;
    line-height: 2;
}
