
/*::v-deep.el-tabs__nav-scroll{*/
/*    display: flex;*/
/*    justify-content: center!important;*/
/*}*/
.teacher-index-container {
    display: flex;
    justify-content: flex-start;
    height: calc(100% - 60px);
    box-sizing: border-box;
    background: #F7F8FC;
    overflow: auto;
    position: fixed;
    width: calc(100% - 230px);
    top: 60px;
    bottom: 0;

    .course-left {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: 100%;
        background-color: #fff;
        margin-right: 20px;

        &.exam {
            width: 210px;
        }

        .select-course {
            width: 260px;
            height: 55px;
            background: #F5F7FA;
            border: 1px solid #DDDDDD;
            border-radius: 6px;
            margin: 20px;

            ::v-deep .el-input__inner {
                color: #333;
                border: transparent;
                background-color: #F5F7FA;
                height: 55px;
            }
        }

        .item-title {
            position: relative;
            font-size: 15px;
            margin: 0 20px;
            height: 40px;
            display: flex;
            align-items: center;

            &:before {
                content: '';
                position: absolute;
                top: 17px;
                width: 6px;
                height: 10px;
                background: #2DC079;
                border-radius: 3px;
            }

            .text {
                margin-left: 15px;
                font-size: 16px;
            }

            &.choseAll {
                background: #2DC079;
                border-radius: 20px;
                cursor: pointer;
                color: #ffffff;

                &:before {
                    content: none;
                }
            }
        }

        .zhang-jie {
            flex: 1;
            height: 1%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .zhang-jie-item {
                padding: 0 20px 20px;
                color: #999;

                .zhang {
                    height: 30px;
                    line-height: 30px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 9px;
                        width: 6px;
                        height: 10px;
                        background: #2DC079;
                        border-radius: 3px;
                    }

                    .text {
                        margin-left: 15px;
                        font-size: 16px;
                    }

                }

                .jie-box {
                    background: #F6FDFC;

                    .jie-inside-box {
                        padding: 8px 0;
                    }
                }

                .jie {
                    cursor: pointer;
                    height: 40px;
                    line-height: 30px;
                    /*color: #409EFF;*/
                    padding-left: 40px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                }

                .current {
                    background: #2DC079;
                    border-radius: 20px;
                    color: #ffffff;
                }

                &.isAllocatedClass {
                    color: #333;

                    .jie {
                        cursor: pointer;
                    }
                }
            }
        }

        .exam-content {
            flex: 1;
            height: 1%;

            ::v-deep .el-scrollbar__wrap {
                margin-top: 20px;
                overflow-x: hidden;
            }

            .exam-item {
                width: 210px;
                height: 60px;
                display: flex;
                align-items: center;
                cursor: pointer;

                &.active {
                    background: #E7F6EF;
                    color: #2DC079;
                }

                .exam-name {
                    margin-left: 37px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap
                }
            }
        }
    }

    .course-right {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1;
        width: 1%;
        margin: 20px 20px 0 0;

        .container {
            flex: 1;
            height: 1%;
        }

        .index-tabs {
            width: 100%;
            height: auto;
            background: transparent;
            padding: 0;

            ::v-deep .el-tabs__header {
                background-color: #fff;
                padding: 0 20px;

                ::v-deep .el-tabs__active-bar {
                    display: none;
                }

                .el-tabs__nav {
                    width: calc(100% - 209px);
                    display: flex;
                    justify-content: center;

                    .el-tabs__item {
                        height: 60px;
                        line-height: 60px;

                        &.is-active {
                            &:after {
                                height: 2px;
                                width: calc(100% - 40px);
                                background-color: #409EFF;
                                content: '';
                                position: absolute;
                                bottom: 0;
                                left: 20px;
                            }

                            &:last-child:after {
                                width: calc(100% - 20px);
                            }

                            &:nth-child(2):after {
                                width: calc(100% - 20px);
                                left: 0;
                            }
                        }
                    }
                }

                .el-tabs__nav-wrap::after {
                    background-color: transparent;
                }
            }

            ::v-deep .el-tabs__content {
                background: #f4f5f5;
            }
        }

        .search-input {
            position: absolute;
            top: 15px;
            right: 20px;
            width: 270px;

            ::v-deep .el-input__inner {
                height: 30px;
                line-height: 30px;
            }
        }
    }
}
