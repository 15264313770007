
.view-container {
    width: 100vw;
    height: 100vh;
    min-width: 1200px;
    background: #F7F8FC;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .header {
        width: 100%;
        height: 60px;
        background: #fff;
        box-shadow: 0 2px 5px 0 rgba(15, 26, 54, 0.05);
        display: flex;
        z-index: 9;

        .login-box {
            width: 100%;
            font-size: 16px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            .title {
                font-size: 18px;
                font-weight: 400;
                color: #333;
            }

            img {
                height: 50px;
                width: 94px;
            }

            .user-info {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                color: #2DC079;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;

                .logout-btn {
                    display: inline-block;
                    margin-right: 8px;
                    font-size: 18px;
                }
            }
        }
    }

    .main {
        width: 100%;
        height: 1%;
        flex: 1;
        display: flex;

        .menu-box {
            width: 210px;
            height: 100%;
            margin-right: 20px;
            background-color: #fff;
        }

        .levelOne-box {
            position: relative;
            width: 100%;
            height: 100%;
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .levelOne {
                height: 60px;
                padding: 0 10px 0 30px;
                display: flex;
                align-items: center;
                color: #666;
                line-height: 1;
                transition: all .3s;

                .iconfont {
                    font-size: 16px;
                }

                .one-text {
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 10px;
                }

                .icon {
                    display: inline-block;
                    width: 5px;
                    height: 60px;
                    background: transparent;
                    position: relative;
                    z-index: 999;
                    left: -30px;
                }

                &:hover {
                    color: #1AB163;
                }

                &.current {
                    color: #1AB163;
                    background: #E7F6EF;

                    .icon {
                        background: #1AB163;
                    }
                }
            }
        }

        .main-container {
            display: flex;
            flex: 1;
            flex-direction: column;
            width: 1%;
            background: #fff;
            margin-right: 20px;

            .levelTwo-wrapper {
                height: 46px;
                border-bottom: 2px solid #F1F5FF;
                margin: 20px 20px 0 20px;

                .levelTwo {
                    display: inline-block;
                    width: 163px;
                    height: 100%;
                    line-height: 46px;
                    border-radius: 4px 4px 0 0;
                    text-align: center;
                    color: #333333;
                    font-size: 16px;

                    &:hover {
                        color: #1AB163;
                    }

                    &.current {
                        color: #1AB163;
                        background: #E7F6EF;
                    }

                    &.single {
                        background: unset;
                        color: #333333;
                        text-align: left;
                    }
                }
            }

            .content {
                flex: 1;
                height: 1%;
                padding-top: 20px;
            }
        }
    }
}
