
.progress-btn {
    cursor: pointer;
}

.stepActive {
    background: #20D08C;
    border-color: #20D08C !important;
}

.xianActive {
    border-color: #20D08C !important;
}

.BoothAdvertisingAdd {
    height: 100%;
    /*padding: 20px 40px;*/
    background: #F7F9FB;

    .BoothAdvertisingAddHeader {
        span {
            font-size: 16px;
        }
    }

    .BoothAdvertisingAdd-box {
        display: flex;

        .BoothAdvertisingAdd-left {
            width: 190px;

            .left-creation {
                display: flex;
                flex-direction: column;

                p:nth-child(1) {
                    margin: 0;
                    padding: 20px 0;
                    line-height: 1;
                    color: #333333;
                    font-size: 48px;

                    span {
                        font-size: 24px;
                    }
                }

                .left-step {
                    .stepOne {
                        line-height: 0;
                        display: flex;
                        align-items: center;
                        padding-left: 10px;

                        span {
                            display: inline-block;
                        }

                        span:nth-child(1) {
                            width: 14px;
                            height: 14px;
                            border: 1px solid #DFDFDF;
                            border-radius: 50%;
                        }

                        span:nth-child(2) {
                            margin-left: 16px;
                            font-size: 20px;
                        }
                    }

                    .stepXian {
                        border-left: 1px solid #DFDFDF;
                        margin-left: 17px;
                        line-height: 1;
                        padding-left: 23px;
                        padding-top: 10px;
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 30px;

                        span:nth-child(1) {
                            color: #333333;
                            font-size: 14px;
                        }

                        span:nth-child(2) {
                            margin-top: 6px;
                            color: #1E63F1;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .BoothAdvertisingAdd-right {
            width: calc(100% - 230px);
            /*height: 100%;*/
            background: #ffffff;
            margin-top: 20px;
        }
    }
}
