
.stepActive {
    background: #20D08C;
    border-color: #20D08C!important;
}
.xianActive {
    border-color: #20D08C!important;
}
.info-step {
    height: 100%;
    background: #F7F9FB;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .info-step-header {
        padding: 22px 0 0 40px
    }
    .info-step-box {
        display: flex;
        .info-step-left {
            width: 190px;
            margin-left: 40px;
            .left-creation {
                display: flex;
                flex-direction: column;
                p:nth-child(1) {
                    margin: 0;
                    padding: 20px 0;
                    line-height:1;
                    /*color: #333333;*/
                    color: #959697;
                    font-size: 48px;
                    span {
                        font-size: 24px;
                    }
                    &.isActive {
                        color: #333;
                    }
                }
                .left-step {
                    .stepOne {
                        line-height: 0;
                        display: flex;
                        align-items: center;
                        padding-left: 10px;
                        color: #959697;
                        span {
                            display: inline-block;
                        }
                        span:nth-child(1) {
                            width: 14px;
                            height: 14px;
                            border: 1px solid #DFDFDF;
                            border-radius: 50%;
                        }
                        span:nth-child(2) {
                            margin-left: 16px;
                            font-size: 20px;
                        }
                        &.isActive {
                            color: #333;
                        }
                    }
                    .stepXian {
                        border-left: 1px solid #DFDFDF;
                        margin-left: 17px;
                        line-height: 1;
                        padding-left: 23px;
                        padding-top: 10px;
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 30px;
                        span:nth-child(1) {
                            color: #333333;
                            font-size: 14px;
                        }
                        span:nth-child(2) {
                            cursor: pointer;
                            margin-top: 6px;
                            color: #1E63F1;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .info-step-right{
            width: 1%;
            flex: 1;
            margin: 20px 30px 50px 0;
        }
    }
}
