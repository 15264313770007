
.promotion-content {
    width: 100vw;
    height: 100vh;
    display: flex;
}
.left-box {
    width: 280px;
    background: #2C405F;
    height: 100%;
    .top-box {
        color: #fff;
        text-align: center;
        margin-top: 36px;
        .iconfont {
            display: inline-block;
            width: 60px;
            height: 60px;
            line-height: 60px;
            background: #FD4446;
            border-radius: 6px;
            font-size: 33px;
        }
        .text {
            margin-top: 22px;
            font-size: 28px;
            line-height: 1;
            font-weight: 500;
        }
    }
    .left-menu {
        margin-top: 50px;
        background-color: transparent;
        border-right: 0;
        .el-menu-item {
            height: 60px;
            line-height: 60px;
            padding: 0!important;
            .nav-item {
                height: 100%;
                color: #fff;
                font-size: 20px;
                display: flex;
                align-items: center;
                .iconfont {
                    color: #fff;
                    font-size: 20px;
                    margin: 0 20px 0 40px;
                }
            }
            &:focus, &:hover {
                background-color: #25354F;
                .nav-item {
                    color: #fff;
                    .iconfont {
                        color: #fff;
                    }
                }
            }
            &.is-active {
                background: #25354F;
                .nav-item {
                    color: #fff;
                    &:before {
                        content: '';
                        width: 2px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #FD4446;
                    }
                    .iconfont {
                        color: #fff;
                    }
                }
            }
            .nav-item-data {
                background: #2C405F;
            }
            &.is-active {
                .nav-item-data {
                    &:before {
                        background: #2C405F;
                    }
                }
            }
        }
    }
}
.main-box {
    width: 1%;
    flex: 1;
    background: #F7F9FB;
    height: 100%;
    display: flex;
    flex-direction: column;
    .header-box {
        height: 79px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-bottom: 1px solid #D1D2DB;
        position: relative;
        .time-out {
            .count-down {
                color: #FD4446;
            }
        }
        .header-right {
            position: absolute;
            right: 30px;
            display: flex;
            align-items: center;
            font-size: 16px;
            .user-info {
                display: flex;
                align-items: center;
                .avatar {
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    margin-right: 10px;
                    img {
                        max-width: 100%;
                    }
                }
            }
            .back-btn {
                margin-left: 30px;
                cursor: pointer;
                transition: all .3s;
                .iconfont {
                    margin-right: 10px;
                }
                &:hover {
                    color: #1122D8;
                }
            }
        }
    }
    .content-box {
        height: 1%;
        flex: 1;
    }
}
