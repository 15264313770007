
.count-down {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    i {
        color: #fff;
        font-size: 20px;
        margin-right: 10px;
    }
}
.is-exam {
    color: #333;
    i {
        color: #2DC079;
    }
}
